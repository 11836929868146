require('firebase/analytics');
require("firebase/functions");

require('./src/styles/normalize.css');
require('./src/styles/typography.css');

const React = require("react");
// const BookingSidebar = require("./src/components/BookingSidebar").default;
const ClientInit = require('./src/components/ClientInit').default;
const PagesProvider = require('./src/context/pagesContext').PagesProvider;

export const wrapPageElement = ({ element, props }) => {
    return (
        <PagesProvider>
            {element}
            {/* <BookingSidebar {...props} /> */}
            <ClientInit />
        </PagesProvider>
    );
};
