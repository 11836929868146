/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createContext, useState } from 'react';
import { StylesProvider, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { colors } from '../utils/preset';

const theme = createMuiTheme({
    palette: {
        primary: { main: colors.theme },
    },
    typography: {
        fontFamily: '"Cera PRO", sans-serif',
        button: {
            fontFamily: 'Cera PRO Medium',
            textTransform: 'none',
        }
    },
});

const PagesContext = createContext({});

const PagesProvider = ({ children }) => {
    const [pages, setPages] = useState([]);

    return (
        <PagesContext.Provider value={{ pages, setPages }}>
            <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    {children}
                </StylesProvider>
            </ThemeProvider>
        </PagesContext.Provider>
    );
};

export { PagesContext, PagesProvider };
