const colors = require(`./colors`).default;

module.exports = {
    colors,
    breakpoints: {
        xs: `@media (min-width: 375px)`,
        sm: `@media (min-width: 600px)`,
        md: `@media (min-width: 960px)`,
        lg: `@media (min-width: 1366px)`,
        xl: `@media (min-width: 1920px)`,
    },
    spacing: {
        xxs: 8,
        xs: 12,
        sm: 16,
        md: 24,
        lg: 40,
        xl: 64,
        xxl: 80,
    },
    border: `solid 1.5px ${colors.theme}`,
    transition: 'all 300ms cubic-bezier(.165, .84, .44, 1)',
};
